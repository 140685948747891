<template>
	<div class="ysm-order-five">
		
				<div align="left" class="left"  >
						<img src="../../assets/ysmimg/ysmorderoneimg.png" width="90px" height="110px" style="vertical-align: middle;" >
						<div>
							<h3>上传口扫</h3>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;">请在此处上传口扫文件。请注意文件格式。</div>
							<div align="left"style="font-size: 0.9375rem;line-height: 1.875rem;" >您可以在<a href="./ks.pdf" target="_blank" style="color: #af9f73;font-weight: bold;text-decoration: underline;" >此处</a>找到有关图片的示例。</div>
							<div align="left"style="font-size: 0.9375rem;line-height: 1.875rem;">重要提示：要创建一个治疗方案，需要上、下颌的3D扫描文件及咬合记录。
							</div>
							
							<div>
								<el-form :model="ruleForm" ref="ruleForm">
									<el-form-item prop="chengxiangDate" label="成像日期" required>
										<el-date-picker :picker-options="filerData.pickerOptions" type="date" placeholder="成像日期" v-model="ruleForm.chengxiangDate"
											style="width: 100%;" value-format="yyyy-MM-dd" format="yyyy-MM-dd"></el-date-picker>
									</el-form-item>
								</el-form>
							</div>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">注意</span>：图像需近30天内</div>
							<div align="left"style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">有效格式</span>：.stl、.obj</div>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最小文件大小</span>：2MB</div>
							<div align="left" style="font-size: 0.9375rem;line-height: 1.875rem;"><span style="font-weight: bold;">最大文件大小</span>：25MB</div>
						</div>
				</div>
				
				<div class="right">
					<!-- 上颌 -->
					<div class="shanghe" >
						<div style="position: relative;left: -15.25rem;">上传上颌<span style="color: red;">*</span></div>
						<el-upload class="upload-demo" drag action='' ref="upload" :http-request="changeFile" multiple accept=".png,.stl,.obj,.jpg">
							<!-- <img v-show="shVisiable" class="sh" :src="shPicUrl" style="width: 100%; height: 100%;" /> -->
							<div v-if="shVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color:#af9f73;color: white;" >上颌上传成功</div>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="el-upload__tip" slot="tip"></div>
						</el-upload>
						<!--  accept=".jpg,.png"
						:before-upload="beforeAvatarUpload" -->
					</div>
					<!-- 下颌 -->
					<div class="xiahe" >
						<div style="position: relative;left: -15.25rem;">上传下颌<span style="color: red;">*</span></div>
						<el-upload class="upload-demo1" drag action='' multiple :http-request="changeFile1" accept=".png,.stl,.obj,.jpg">
							<!-- <img v-show="xhVisiable"  class="xh":src="xhPicUrl" style="width: 100%; height: 100%;" /> -->
							<div v-if="xhVisiable" style="width: 100%; height: 30px;line-height: 30px;position: absolute;top: 0px;background-color:#af9f73;color: white;" >下颌上传成功</div>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="el-upload__tip" slot="tip"></div>
						</el-upload>
					</div>
					<!-- 咬合 -->
					<!-- <div class="yaohe" v-if="yh">
						<div style="margin-top: 0.3125rem;font-size: 0.875rem;">请上传咬合</div>
						<el-upload class="upload-demo1" drag action='' multiple :http-request="changeFile2" accept=".png,.stl,.obj">
							<span class="yh"></span><br />
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="el-upload__tip" slot="tip"></div>
						</el-upload>
					</div> -->
					<div style="text-align: right;">
					<el-button @click="goOrderThree" class="back"  type="button">返回</el-button>
					<el-button @click="nextStepKN" class="nextStep" type="button">下一步</el-button>
					</div>
				</div>
			
		
				
				
			
		
	</div>
</template>

<script>
	export default {
		data: function() {
			return {
				filerData: {
				  pickerOptions: {
				    disabledDate(time) {
				      return time.getTime() > Date.now() - 8.64e6
				    }
				  },
				},
				shPicUrl: '',
				xhPicUrl: '',
				xhVisiable:false,
				shVisiable:false,
				ruleForm: {
					chengxiangDate: '',
					oid: '',
				}
			}
		},
		methods: {
		

			//上传上颌
			changeFile(file) {
				console.log(file);
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksShang')
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						console.log(res.data);
						
						if (res.data.code == 200) {
							this.shPicUrl=res.data.data.picUrl;
							this.shVisiable=true;
							this.$message({
								message:"上传上颌成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//上传下颌
			changeFile1(file) {
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksXia')
				console.log(formData.get('type'));
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						
						if (res.data.code == 200) {
							this.xhPicUrl=res.data.data.picUrl;
							this.xhVisiable=true;
							this.$message({
								message:"上传下颌成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//上传下颌
			changeFile2(file) {
				let formData = new FormData()
				formData.append('file', file.file) // 传文件
				formData.append('oid', this.ruleForm.oid)
				formData.append('type', 'ksYao')
				console.log(formData.get('type'));
				let self = this
				let config = {
					headers: {
						"Content-Type": "multipart/form-data"
					}
				};
				this.$axios.post('client/order/uploadResourceFile', formData, config)
					.then(res => {
						if (res.data.code == 200) {
							this.$message({
								message:"上传咬合成功",
								type:"success",
								duration:900
							});
						}
					}).catch(err => {
						console.log(err);
					});
			},
			//下一步
			nextStepKN() {
				if(this.ruleForm.chengxiangDate==''){
					// this.$message({
					// 	message:"请选择成像日期",
					// 	type:"warning",
					// 	duration:900
					// });
					this.$alert("请选择成像日期","提示");
					
				}else if(this.shPicUrl==""){
					this.$alert("请上传上颌图片","提示");
				}else if(this.xhPicUrl==""){
					this.$alert("请上传下颌图片","提示");
				}else{
					//上传日期
					let formData1 = new FormData()
					formData1.append('date', this.ruleForm.chengxiangDate) // 传文件
					formData1.append('oid', this.ruleForm.oid)
					formData1.append('type', "ksDate")
					
					this.$axios.post("client/order/uploadResourceDate", formData1, {
						headers: {
							"Content-type": "application/json"
						}
					}).then(res => {
						console.log(res);
						if (res.data.code != 200) {
							this.$message({
								message:"添加成像日期失败",
								type:"error",
								duration:900
							});
						}else{
							this.$store.commit("setYsmStepIndex",6);
							this.$router.push("/ysmmain/ysmorder/ysmordersix");
						}
					}).catch(err => {
						this.$message({
							message:"请联系管理员",
							type:"error",
							duration:900
						});
					});
					
					
				}
				
			},
			//返回
			goOrderThree(){
				this.$router.push("/ysmmain/ysmorder/ysmorderfour")
			}
		},
		mounted: function() {
			this.$store.commit('setStepIndex', 5);
			//设置默认地址
			sessionStorage.setItem("location","/ysmmain/ysmorder/ysmorderfive")
			//获取当前添加的订单oid
			//获取oid
			let oid = sessionStorage.getItem("oid");
			console.log("口扫" + oid);
			//this.ruleForm.oid="5e63248b2006487ebdcb5ff381f1360b"; 
			// if(oid==null){
			// 	this.$alert("请填写患者信息","提示");
			// 	this.$router.push("/addorder/orderone");
			// }else{
			// 	this.ruleForm.oid=oid;
			// }
			document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 visible")
			document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue")
			document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3")	
			//设置返回路径
			this.$store.commit("setBackLocation","/ysmmain/ysmorder/ysmorderfour");
		},
		created:function(){
			//如果是编辑订单要获取oid的值
			let oid=sessionStorage.getItem("oid");
		
			if(oid!="undefined"&&oid!=''&&oid!=null){
				this.ruleForm.oid=oid;
				this.$axios.get("/client/order/getOrderInfo",{
					params:{
						oid:this.ruleForm.oid
					}
				}).then(res=>{
					console.log(res.data.data.resource);
					let ksDate=res.data.data.resource.ksDate;//口扫日期
				
					let shUrl=res.data.data.resource.ksShang;//上颌
					let xhUrl=res.data.data.resource.ksXia;//下颌
					
					//日期回显
					if(ksDate!=null){
						this.ruleForm.chengxiangDate=ksDate;
					}
					
					//回显上颌
					if(shUrl!=null&&shUrl!=''){
						this.shVisiable=true;
						this.shPicUrl=shUrl;
						console.log(document.getElementsByClassName("sh")[0]);
						// document.getElementsByClassName("sh")[0].setAttribute("src",shUrl)
						
					}
					//回显下颌
					if(xhUrl!=null&&xhUrl!=''){
						this.xhVisiable=true;
						this.xhPicUrl=xhUrl;
						// document.getElementsByClassName("xh")[0].setAttribute("src",xhUrl)
						
					}
					
				}).catch(err=>{
					console.log(err);
				});
			}else{
			this.$alert('请填写患者信息', '提示', {
				confirmButtonText: '确定',
				callback: action => {
					this.$router.push("/ysmmain/ysmorder/ysmorderone");
				}
			});
			
			}
			
		}
	}
</script>

<style scoped="scoped">
	.ysm-order-five{
		width: 80%;
		display: flex;
		margin: 0px auto;
		margin-top: 3.125rem;
		padding-bottom: 5.125rem;
		justify-content: space-around;
	}
	
	h3{
		font-family: 'themixitalic';
		font-weight: 400;
		line-height: 1.1;
		color: #af9f73;
		margin-bottom: 20px;
		font-size: 24px;
	}
	.left{
		display: flex;
		width: 30rem;
		position: relative;
		justify-content: flex-start;
	}
	.right{
		/* position: relative;
		 width: 35rem;
		 left: 1.1rem; */
		 width: 560px;
	}
	

	
	.xy {
		display: none;
	}
	/* 按钮 */
	.nextStep{
		background-color: #af9f73 !important;
		border-color: #af9f73 !important;
		color: #fff !important;
		min-width: 7.5rem;
	}
	.back{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #af9f73 !important;
		color: #af9f73 !important;
	}
	
</style>
